import request from '@/utils/request';

// 获取专业树信息
export function majorTreeInfo(params) {
  return request({
    url: '/profession-info/getProfessionTree',
    method: 'get',
    params,
  });
}
// 根据专业名称查询专业类别
export function getProfessionIdByName(params) {
    return request({
      url: '/profession-deatil/getProfessionIdByName',
      method: 'get',
      params,
    });
  }

// 根据专业获取专业详情
export function getByProfessionId(params) {
  return request({
    url: '/profession-deatil/getByProfessionId',
    method: 'get',
    params,
  });
}

// 查询学校信息--返回页
export function universityInfo(params) {
  return request({
    url: '/university/pageQuery',
    method: 'get',
    params,
  });
}

// 查询学校信息--返回全部
export function universityInfoAll(params) {
  return request({
    url: '/university/pageQuery',
    method: 'get',
    params,
  });
}
