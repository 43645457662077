<template>
  <div class="base-box"
    :class="isCenter && 'box-center'"
    :style="`width: ${boxWidth}; min-height: ${boxHeight};`"
  >
    <div class="top-bar">
      <img class="top-bar-icon" :src="topBarIcon" alt="icon">
      <label class="top-bar-label boldFont">{{ topBarLabel }}</label>
    </div>
    <div class="box-content">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBox',
  props: {
    boxWidth: {
      type: String,
      default: '100%',
    },
    boxHeight: {
      type: String,
      default: 'unset',
    },
    isCenter: {
      type: Boolean,
      default: true,
    },
    topBarIcon: {
      type: String,
      default: `${process.env.BASE_URL}icon_scheml_search@3x.png`,
    },
    topBarLabel: {
      type: String,
      default: 'topBarLabel',
    },
  },
  data() {
    return {

    };
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.base-box {
  background: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(1,31,74,0.40);
  border-radius: 4px;
}
.box-center {
  margin: 0 auto 20px;
}

.top-bar {
  display: flex;
  align-items: center;
  padding: 20px;

  .top-bar-icon {
    width: 28px;
    height: 28px;
  }

  .top-bar-label {
    font-size: 18px;
    color: #333333;
    line-height: 18px;
  }
}

.box-content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 40px;

  .content {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .content-box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: $autoHeight;
    font-size: 18px;
    padding-left: 40px;
    margin-bottom: 20px;
    color: #333333;
    background: #F5F5F5;
    border-radius: 4px;

    .content-list-item {
      // height: 40px;
      padding: 0 36px;
      margin-right: 16px;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      color: #409EFF;
      background: #FFFFFF;
      border: 1px solid #409EFF;
      box-shadow: 0 2px 4px 0 rgba(221,221,221,0.60);
      border-radius: 20px;
    }
  }
}

.boldFont {
  font-weight: bold;
}
</style>
