<template>
    <div class="major-info">
        <base-box :topBarIcon="majorInfoIcon" topBarLabel="专业介绍" boxWidth="95%" boxHeight="500px" style="margin-top: 20px;">
            <div slot="content" style="display: flex;flex-flow: wrap;justify-content: space-between;">
                <div class="nav-tree" style="margin-top:10px;width:20%;">
                    <el-input class="input" style="margin-bottom:15px;" placeholder="输入专业名称" v-model="majorName" clearable @clear="getMajorInfo">
                    </el-input>
                    <el-tabs type="border-card" @tab-click="handleClick" style="100%;">
                        <el-tab-pane label="本科">
                            <div style="height:400px;overflow-y:auto;" class="treem">
                                <el-tree :data="dataBenk" v-if="dataBenk.length" ref="tree1" highlight-current :props="defaultProps" node-key="id" :current-node-key="parentId" :default-expanded-keys="treeExpandData" :accordion="true" @node-click="handleNodeClickBenk">
                                </el-tree>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="专科">
                            <div style="height:400px;overflow-y:auto;" class="treem">
                                <el-tree :data="dataZhuank" v-if="dataZhuank.length" ref="tree" highlight-current node-key="id" :props="defaultProps" :current-node-key="parentId" :default-expanded-keys="treeExpandData" accordion @node-click="handleNodeClickZhuank">
                                </el-tree>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 针对点击事件显示相应的 -->
                <div class="main-content">
                    <div v-if="i === 1" style="margin-bottom:60px;">
                        <!-- 插入姓名 -->
                        <!-- <div class="bigMajor">{{ list.category1 }}</div>
            <div class="subject1">
              <span>{{ list.family1 }}</span>
              <span> > </span>
              <gspan>{{ list.bigCategory1 }}</gspan>
            </div> -->
                        <div class="breadmm">
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item>{{ list.family1 }}</el-breadcrumb-item>
                                <el-breadcrumb-item>{{ list.bigCategory1 }}</el-breadcrumb-item>
                                <el-breadcrumb-item>{{ list.category1 }}</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <div class="majorDetailed1">
                            <div style="clear:both;">
                                <div class="details1" style="float:left;">专业详情</div>
                                <div style="float:right;width:13%;">
                                    <!-- <div style="display:flex;"> -->
                                    <div class="baoHan1">包含专业{{`(${categoryNumber})个`}}</div>
                                    <!-- <div class="categorynumber">
                      <span style="margin-left:15px;">(</span>
                      <span>{{ categoryNumber }}</span>
                      <span>个</span>
                      <span>)</span>
                    </div> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                            <div class="lineCommonTall" style="clear:both;"></div>
                        </div>
                        <!-- <div style="display:flex;margin-bottom:25px;">
              <div class="lineCommon"></div>
              <div style="display:flex;">
                <div class="baoHan" style="font-size:17px;">包含专业</div>
                <div class="categorynumber">
                  <span style="margin-left:15px;">(</span>
                  <span>{{ categoryNumber }}</span>
                  <span>个</span>
                  <span>)</span>
                </div>
              </div>
            </div> -->
                        <!-- 查看详情v-for  这部分内容循环 -->
                        <div class="containerVFor" v-for="(item, index) in subjectList" :key="index">
                            <div class="containSubjects">
                                <div style="height:70px;width:890px;">
                                    <div class="xiaoKeMu">{{ item.subject1 }}</div>
                                    <div style="display:flex;font-size:14px;">
                                        <div style="width: 190px;text-align: left;">
                                            <span>国标代码:</span>
                                            <span style="padding-left:12px;">{{
                        item.nationalCode
                      }}</span>
                                        </div>
                                        <div style="width: 120px;text-align: left;">
                                            <span>学制:</span>
                                            <span style="padding-left:12px;">{{
                        `${item.systemYear}年`
                      }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttonHandle">
                                    <el-button style="font-size:13px;" type="text" @click="handleDetail(item.subject1, item.nationalCode)">查看详情</el-button>
                                </div>
                            </div>
                        </div>
                        <!-- <div style="height:40px;"></div> -->
                    </div>

                    <!-- 查看详情 -->
                    <div v-if="i === 2">
                        <div class="bigMajor">{{ subjectOnly }}</div>
                        <!-- <div class="subjectBox">
              <div class="subject1">
                <span>{{bigCategory}}</span>
                <span> > </span>
                <span>{{category}}</span>
              </div>
              <div class="codeNation">
                <span>国标代码:</span>
                <span style="margin-left:12px;">{{nationalCode}}</span>
                <span style="margin-left:12px;">(不可用于填报)</span>
              </div>
            </div> -->
                        <div class="majorDetailed1">
                            <div>
                                <div class="details1" style="float:left;">专业概况</div>
                                <div class="codeNation" style="float:right;">
                                    <span>国标代码:</span>
                                    <span style="margin-left:12px;">{{ nationalCode }}</span>
                                    <span style="margin-left:12px;">(不可用于填报)</span>
                                </div>
                            </div>
                            <div class="lineCommonTall" style="clear:both;"></div>
                        </div>
                        <div style="height:260px;">
                            <div style="display:flex;">
                                <div class="lineCommon"></div>
                                <div class="baoHan">专业概览</div>
                            </div>
                            <!-- 遍历图片 -->
                            <div class="overviews" v-for="(item, index) in viewList" :key="index">
                                <div class="viewOne">
                                    <div class="viewOnly">
                                        <img :src="item.imgUrl" alt="" style="width: 130px;height:130px;" />
                                    </div>
                                    <div v-if="item.name" class="viewHeader">{{ item.name }}</div>
                                    <div v-else class="viewHeader">{{ "-:-" }}</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both;">
                            <div style="display:flex;">
                                <div class="lineCommon"></div>
                                <div class="baoHan">专业介绍</div>
                            </div>
                            <div>
                                <div class="small" v-if="this.family == '本科'">
                                    <div class="title">
                                        <span style="font-size:25px">.</span>
                                        <span class="title">专业简介</span>
                                    </div>
                                    <div class="mainer">{{ synopsis }}</div>
                                </div>
                                <div class="small">
                                    <div class="title">
                                        <span style="font-size:25px">.</span>
                                        <span class="title">培养目标</span>
                                    </div>
                                    <div class="mainer">{{ goal }}</div>
                                </div>
                                <div>
                                    <div class="small" v-if="this.family == '本科'">
                                        <div class="title">
                                            <span style="font-size:25px">.</span>
                                            <span class="title">培养要求</span>
                                        </div>
                                        <div class="mainer">{{ requirement }}</div>
                                    </div>
                                    <div class="small" v-else>
                                        <div class="title">
                                            <span style="font-size:25px">.</span>
                                            <span class="title">实习实训</span>
                                        </div>
                                        <div class="mainer">{{ training }}</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="small" v-if="this.family == '本科'">
                                        <div class="title">
                                            <span style="font-size:25px">.</span>
                                            <span class="title">学科要求</span>
                                        </div>
                                        <div class="mainer">{{ subject }}</div>
                                    </div>
                                    <div class="small" v-else>
                                        <div class="title">
                                            <span style="font-size:25px">.</span>
                                            <span class="title">职业资格证书举例</span>
                                        </div>
                                        <div class="mainer">{{ credentials }}</div>
                                    </div>
                                </div>
                                <div class="small">
                                    <div class="title">
                                        <span style="font-size:25px">.</span>
                                        <span class="title">知识能力</span>
                                    </div>
                                    <!-- <div class="mainer">{{knowledge}}</div> -->
                                    <div class="mainerKle">{{ knowledge }}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="itemClass" v-if="this.family == '本科'">
                                <div style="display:flex;">
                                    <div class="lineCommon"></div>
                                    <div class="baoHan">考研方向</div>
                                </div>
                                <div class="characters">{{ postGraduateWR }}</div>
                            </div>
                            <div class="itemClass" v-if="this.family == '专科'">
                                <div style="display:flex;">
                                    <div class="lineCommon"></div>
                                    <div class="baoHan">专升本方向</div>
                                </div>
                                <div class="characters">{{ universityFrom }}</div>
                            </div>
                        </div>
                        <div class="itemClass">
                            <div style="display:flex;">
                                <div class="lineCommon"></div>
                                <div class="baoHan">主要课程</div>
                            </div>
                            <div class="characters">{{ mainCourseWR }}</div>
                        </div>
                        <div class="itemClass" :style="this.family == '专科' ? 'margin-bottom:80px;' : ''">
                            <div style="display:flex;">
                                <div class="lineCommon"></div>
                                <div class="baoHan">就业方向</div>
                            </div>
                            <div class="characters">{{ jobOrientationWR }}</div>
                        </div>
                        <div v-if="this.family == '本科'" class="itemClass" style="margin-bottom:80px;">
                            <div style="display:flex;">
                                <div class="lineCommon"></div>
                                <div class="baoHan">社会名人</div>
                            </div>
                            <div class="characters">{{ socialCelebritiesWR }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </base-box>
    </div>
</template>

<script>
    // import {majorTreeInfo} from '@/api/majorCollegeInfo';  //一个
    import * as ApiMajorCollege from "@/api/majorCollegeInfo";
    import BaseBox from "@/components/BaseBox/index.vue";
    import con1 from "@/assets/images/icon_sub_01.jpg";
    import con2 from "@/assets/images/icon_sub_02.jpg";
    import con3 from "@/assets/images/icon_sub_03.jpg";
    import con4 from "@/assets/images/icon_sub_04.jpg";

    const globalRecordIcon = require("@/assets/images/icon_record@3x.png");
    // 后面把图标换一下
    export default {
        name: "MajorInfo",
        components: {
            BaseBox,
        },
        data() {
            return {
                majorName: '', //专业名称搜索
                list: {
                    // category1:"哲学类",
                    // family1:"本科",
                    // bigCategory1: "哲学",
                },
                allZhuank: false,
                allBenk: false,
                keys: "", // 当前选中的节点
                treeExpandData: [], // 自己定义的用于接收tree树id的数组
                treeExpandDataB: [], // 专科--本来就是默认展开的
                provincialCenterId: "",
                msg: "", // v-html的
                synopsis: "", // 专业简介
                goal: "", // 培养目标
                requirement: "", // 培养要求
                training: "", // 实习实训
                subject: "", // 学科要求
                credentials: "", // 职业资格证书举例
                knowledge: "", // 知识能力
                introduceWR: "", // 专业介绍
                postGraduateWR: "", // 考研方向
                universityFrom: "", // 专升本
                mainCourseWR: "", // 主要课程
                jobOrientationWR: "", // 就业方向
                socialCelebritiesWR: "", // 社会名人
                parentId: 1, // 父类的id
                TreeList: [],
                tabs: "1", // 点击tabs标签页--本专科
                subjectList: [
                    // {
                    //   subject: "哲学",
                    //   nationalCode: "112221",
                    //   systemYear: 4
                    // },
                ], // 课目列表
                viewList: [], // 图片列表
                systemYear: "4年", // 学制--符号
                nationalCode: "010101", // 国标代码
                subject1: "哲学", // 遍历的小科目
                subjectOnly: "", // 单独的小科目
                categoryNumber: 0,
                bigCategory: "哲学",
                family: "本科", // 加
                category: "哲学类", // 小类别
                i: 1,
                expandedKeys: [], // 默认展开某个节点node-keys
                majorInfoIcon: globalRecordIcon, // 专业介绍图标
                dataBenk: [], // 本科数组对象
                dataZhuank: [], // 专科数组对象
                defaultProps: {
                    children: "children",
                    label: "name",
                },
            };
        },
        watch: {
            majorName: function(major_name) {
                this.getMajorInfo(major_name);
            }
        },
        mounted() {
            // 一开始
            this.benke(true);
            this.zhuanke();
            this.getProfessionInfo();
            // this.list = {
            //   category1: "哲学类",
            //   family1: "本科",
            //   bigCategory1: "哲学"
            // };
        },
        methods: {
            // 获取树形结构默认展开节点
            getRoleTreeRootNode(provincialCenterId) {
                this.treeExpandData.push(provincialCenterId);
            },
            // 获取专业详情信息
            getProfessionInfo(major_name) {
                // 在mounted中
                const params = {
                    professionId: this.parentId,
                    name: major_name
                };
                ApiMajorCollege.getByProfessionId(params).then((res) => {
                    if (res.code === 0) {
                        // console.log(res.data);
                        const subjectAll = res.data;
                        console.log(res.data);
                        const arr = [];
                        subjectAll.map((item) => {
                            return arr.push({
                                subject1: item.name,
                                nationalCode: item.code,
                                systemYear: item.yearlLimlt,
                            });
                        });
                        // console.log(arr)
                        this.subjectList = arr;
                        this.categoryNumber = arr.length;
                    }
                });
            },
            // tab被选中时触发事件
            handleClick(tab, event) {
                console.log("ffffffffffffffffffffffff");
                this.tabs = tab.label;
                console.log(tab, event);
                if (tab.label === "本科") {
                    this.family = "本科";
                } else {
                    this.family = "专科";
                }
            },
            benke() {
                // 请求接口中的数据
                console.log("本科事件");
                const params = {
                    // type: this.tabs === "1" ? "专科" : "本科",
                    type: 1,
                };
                this.treeExpandData = [];
                ApiMajorCollege.majorTreeInfo(params).then((res) => {
                    if (res.code === 0) {
                        console.log(res.data); // 根据父类的id显示相应的子项---只要拿到本科下面的第一个子类的就可以了
                        this.dataBenk = res.data;
                        this.provincialCenterId = this.dataBenk[0].id;
                        this.getRoleTreeRootNode(this.provincialCenterId);
                        const temp = {
                            parent: {
                                data: {
                                    name: res.data[0].name,
                                },
                            },
                        };
                        this.handleNodeClickBenk(res.data[0].children[0], temp);
                        this.$nextTick(function() {
                            this.$refs.tree1.setCurrentKey(res.data[0].children[0].id);
                        });
                    }
                });
            },

            zhuanke() {
                // 请求专科中的数据
                console.log("专科事件");
                const params = {
                    type: 2,
                };
                ApiMajorCollege.majorTreeInfo(params).then((res) => {
                    if (res.code === 0) {
                        this.dataZhuank = res.data;
                    }
                });
            },

            // 详情
            handleDetail(item, val) {
                console.log(item);
                this.subjectOnly = item;
                this.nationalCode = val;
                if (this.i === 2) {
                    this.i = 1;
                } else {
                    this.i = 2;
                }
                // 从这里再请求一次数据
                const params = {
                    professionId: this.parentId,
                };
                ApiMajorCollege.getByProfessionId(params).then((res) => {
                    if (res.code === 0) {
                        // 根据特定的name返回这个特定对象
                        var obj = res.data.find((obj) => obj.name === item);
                    }
                    console.log(obj);
                    this.synopsis = obj.synopsis;
                    this.goal = obj.goal;
                    this.requirement = obj.requirement;
                    this.training = obj.training;
                    this.subject = obj.subject;
                    this.credentials = obj.credentials;
                    this.knowledge = obj.knowledge;
                    this.introduceWR = obj.introduce;
                    this.postGraduateWR = obj.postGraduate;
                    this.universityFrom = obj.universityFrom;
                    this.mainCourseWR = obj.mainCourse;
                    this.jobOrientationWR = obj.jobOrientation;
                    this.socialCelebritiesWR = obj.socialCelebrities;

                    // 图片相关数据
                    this.viewList = [{
                            imgUrl: con1,
                            name: this.family, // 本科
                        },
                        {
                            imgUrl: con2,
                            name: `${obj.yearlLimlt}年`, // 4年`${this.yearlLimlt}年`}--undefined
                        },
                        {
                            imgUrl: con3,
                            name: obj.diploma, // 学位
                        },
                        {
                            imgUrl: con4,
                            name: obj.artsRatio,
                        },
                    ];
                });
            },
            // 定义一个方法    dataZhuank:数据源---默认展开节点的
            // expandedTree(dataZhuank) {
            //   this.expandedKeys = [];
            //   for (var i = 0; i < dataZhuank.length; i++) {
            //     this.expandedKeys.push(dataZhuank[0].id); //循环data的数据，把对应要展开的节点id放入展开的数组中
            //   }
            //   console.log(this.expandedTree)
            // },
            /**
              * @desc 专业搜索查询方法
              * @author Author jzjun
              * @date 2022-04-28 14:53:10
              */
            getMajorInfo(major_name) {
                if(major_name) {
                    let arr = []
                        let parentName = []
                        this.treeExpandData = []
                        if(this.family === '本科') {
                            const obj = {
                                type: 1,
                                name: major_name
                            }
                            // 本科类型的专业处理
                            ApiMajorCollege.getProfessionIdByName(obj).then((res) => {
                                if (res.code === 0) {
                                    this.treeExpandData = res.data
                                    this.dataBenk.forEach((element,index) => {
                                        if(element?.children) {
                                            element.children.forEach((majorItem,cindex) => {
                                                    // let reg = new RegExp(major_name)
                                                    if(res.data.includes(majorItem.id)) {
                                                        arr.push(majorItem)
                                                        parentName.push(element.name)
                                                        this.list.category1 = arr[0].name
                                                        this.list.bigCategory1 = parentName[0]
                                                        this.list.family1 = this.family
                                                        this.$nextTick(()=>{
                                                            this.parentId = arr[0].id
                                                            this.$refs['tree1'].setCurrentKey(arr[0].id)
                                                            this.getProfessionInfo(majorItem.name)
                                                        })
                                                    }
                                            })
                                        }
                                    })
                                }
                            });
                        } else {
                            const obj = {
                                type: 2,
                                name: major_name
                            }
                            // 专科类型的专业处理
                            ApiMajorCollege.getProfessionIdByName(obj).then((res) => {
                                if (res.code === 0) {
                                    this.treeExpandData = res.data
                                    this.dataZhuank.forEach((element,index) => {
                                        if(element?.children) {
                                            element.children.forEach((majorItem,cindex) => {
                                                    let reg = new RegExp(major_name)
                                                    if(reg.test(majorItem.name)) {
                                                        arr.push(majorItem)
                                                        parentName.push(element.name)
                                                        // this.treeExpandData.push(majorItem.id)
                                                        this.list.category1 = arr[0].name
                                                        this.list.bigCategory1 = parentName[0]
                                                        this.list.family1 = this.family
                                                        this.$nextTick(()=>{
                                                            this.parentId = arr[0].id
                                                            this.$refs['tree'].setCurrentKey(arr[0].id)
                                                            this.getProfessionInfo(majorItem.name)
                                                        })
                                                    }
                                            })
                                        }
                                    });
                                }
                            })
                        }
                        for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
                            this.$refs.tree.store._getAllNodes()[i].expanded = false;
                        }
                         for (let i = 0; i < this.$refs.tree1.store._getAllNodes().length; i++) {
                            this.$refs.tree1.store._getAllNodes()[i].expanded = false;
                        }
                } 
            },
            handleSearch(obj) {
                // console.log(ApiMajorCollege)
                ApiMajorCollege.getProfessionIdByName(obj).then((res) => {
                    if (res.code === 0) {
                        arr.push(majorItem)
                        parentName.push(element.name)
                        this.treeExpandData = res.data
                        this.list.category1 = arr[0].name
                        this.list.bigCategory1 = parentName[0]
                        this.list.family1 = this.family
                        this.$nextTick(()=>{
                            this.parentId = res.data[0]
                            this.$refs['tree1'].setCurrentKey(res.data[0])
                            this.getProfessionInfo(majorItem.name)
                        })
                    }
                });
            },
            handleNodeClickBenk(data, node) {
                // this.$refs.tree.setCurrentKey(node.id)
                console.log(111);
                console.log("iiiii本科iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                if (this.i === 2) {
                    this.i = 1;
                }
                console.log(data, this.treeExpandData); // 从这里拿到数据的--只想要第二层level=2的;
                if (data.level === 1) {
                    this.bigCategory = data.name;
                } else {
                    // this.bigCategory = node.parent.data.name;
                    // this.category = data.name;
                    this.parentId = data.id;
                    this.getProfessionInfo();
                    this.list = {
                        category1: data.name,
                        family1: this.family,
                        bigCategory1: node.parent.data.name,
                    };
                }
                for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
                    this.$refs.tree.store._getAllNodes()[i].expanded = false;
                }
            },
            handleNodeClickZhuank(data, node) {
                console.log("iiiiii专科iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                this.allBenk = false;
                if (this.i === 2) {
                    this.i = 1;
                }
                if (data.level === 1) {
                    this.bigCategory = data.name;
                } else {
                    // this.bigCategory = node.parent.data.name;
                    // this.category = data.name;
                    this.parentId = data.id;
                    this.getProfessionInfo();
                    this.list = {
                        category1: data.name,
                        family1: this.family,
                        bigCategory1: node.parent.data.name,
                    };
                }
                for (let i = 0; i < this.$refs.tree1.store._getAllNodes().length; i++) {
                    this.$refs.tree1.store._getAllNodes()[i].expanded = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
        font-weight: 600;
        color: #2c3e50;
        cursor: text;
    }

    /deep/ .el-breadcrumb__inner {
        color: #2c3e50;
    }

    /deep/ .el-breadcrumb {
        font-size: 18px;
        /* line-height: 1; */
        font-weight: 600;
    }

    .breadmm {
        margin-top: 10px;
    }

    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: rgb(158, 213, 250) !important;
    }

    >>>.treem .el-tree-node__content {
        display: flex;
        align-items: center;
        /* height: 32px;tree的行高 */
        cursor: pointer;
    }

    /deep/ [data-v-453f9ac0] .el-tree-node__label {
        font-size: 14px;
    }

    /deep/ .el-tabs__content {
        padding: 15px;
        // height: 500px;
    }

    /deep/ .el-button--text {
        color: #409eff;
    }

    /deep/ .el-tabs__item {
        height: 45px;
        box-sizing: border-box;
        line-height: 45px;
        font-size: 16px;
        padding: 0;
        font-weight: 500;
        /* color: #303133; */
        /* width: 130px;  ?????? */
        width: 125%;
    }

    /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        color: white;
        background-color: #409eff;
    }

    .viewHeader {
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        width: 130px;
        margin-top: 8px;
        text-align: center;
        margin-left: 15px;
    }

    .viewOnly {
        height: 130px;
        width: 130px;
        background-color: #ccc;
        margin-left: 15px;
    }

    .viewOne {
        width: 130px;
        height: 200px;
        margin: 20px 50px 0 50px;
    }

    /* //概览图片div */
    .overviews {
        /* // display: flex; */
        float: left;
        height: 200px;
        width: 220px;
    }

    .main-content {
        min-height: 545px; //500
        width: 77%;
    }

    .bigMajor {
        height: 70px;
        min-width: 300px;
        line-height: 70px;
        font-size: 25px;
        font-weight: 600;
        text-align: left;
    }

    .subjectBox {
        display: flex;
        font-size: 20px;
    }

    .subject1 {
        height: 30px;
        line-height: 30px;
        min-width: 200px;
        margin-top: 10px;
        font-size: 16px;
        text-align: left;
    }

    .codeNation {
        font-size: 14px;
        width: 350px;
        text-align: right;
        padding: 10px;
        height: 40px;
        line-height: 40px;
        margin-right: 1%;
    }

    .majorDetailed1 {
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .details1 {
        background-color: #409eff;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        width: 170px;
        color: white;
        margin-bottom: 2px;
        /* // text-align: left; */
    }

    .xiaoKeMu {
        height: 30px;
        min-width: 200px;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
    }

    .containSubjects {
        height: 70px;
        margin-top: 20px;
        display: flex;
        width: 98%;
        /* width: 930px; */
        border-bottom: 1px dashed #ccc;
    }

    .buttonHandle {
        height: 60px;
        margin: auto;
    }

    .categorynumber {
        margin-left: 15px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
    }

    /* //--公共的---------------------- */
    .lineCommon {
        height: 30px;
        width: 4px;
        background-color: #409eff;
    }

    .lineCommonTall {
        height: 3px;
        width: 98%;
        background-color: #409eff;
    }

    .baoHan1 {
        padding-left: 10px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .baoHan {
        padding-left: 10px;
        height: 30px;
        line-height: 30px;
        font-size: 17px;
        font-weight: bold;
    }

    .characters {
        text-align: left;
        width: 100%;
        height: auto;
        margin-top: 15px;
        font-size: 15px;
        color: rgb(102, 102, 102);
        line-height: 30px;
    }

    /* // 子项 */
    .itemClass {
        margin-top: 35px;
    }

    .small {
        width: 100%;
        margin-top: 15px;
    }

    .title {
        font-size: 17px;
        color: rgb(102, 102, 102);
        text-align: left;
        /* margin-top: 30px; */
        margin-bottom: 10px;
    }

    .mainer {
        font-size: 15px;
        color: rgb(102, 102, 102);
        text-align: left;
        line-height: 30px;
    }

    .mainerKle {
        /* 忽略空白  保留换行,设置字体行高 */
        white-space: pre-line;
        font-size: 16px;
        color: rgb(102, 102, 102);
        text-align: left;
    }
</style>